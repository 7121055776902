<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label>Username</label>
            <InputText id="username" v-model="item.mcu_code" required="true" autofocus :class="{ 'p-invalid': errorEdit.username }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.username">{{errorEdit.username[0]}}</small>
        </div>
        <div class="field">
            <label>Nama</label>
            <InputText id="nama" v-model="item.mcu_name" required="true" autofocus :class="{ 'p-invalid': errorEdit.nama }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.nama">{{errorEdit.nama[0]}}</small>
        </div>
        <div class="field">
            <label>No Hp</label>
            <InputText id="no_hp" v-model="item.mcu_phone" required="true" autofocus :class="{ 'p-invalid': errorEdit.no_hp }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.no_hp">{{errorEdit.no_hp[0]}}</small>
        </div>
        <div class="field">
            <label>Email</label>
            <InputText id="email" v-model="item.mcu_email" required="true" autofocus :class="{ 'p-invalid': errorEdit.email }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.email">{{errorEdit.email[0]}}</small>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        // 
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 250);
            },
        },
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/sp/update',
                data: {
                    "mcu_id" : this.item.mcu_id,
                    "username" : this.item.mcu_code,
                    "nama" : this.item.mcu_name,
                    "no_hp": this.item.mcu_phone,
                    "email": this.item.mcu_email,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal diupdate', life: 3000,});
            });
		},
    }
}
</script>