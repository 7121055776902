<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="username">Username</label>
                <InputText id="username" v-model="forms.username" required="true"  :class="{ 'p-invalid': errorAdd.username }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.username" >{{ errorAdd.username[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="nama">Nama</label>
                <InputText id="nama" v-model="forms.nama" required="true"  :class="{ 'p-invalid': errorAdd.nama }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.nama" >{{ errorAdd.nama[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="password">Password</label>
                <Password id="password" v-model="forms.password" required="true"  :class="{ 'p-invalid': errorAdd.password }" toggleMask/>
                <small class="p-invalid" style="color: red" v-if="errorAdd.password" >{{ errorAdd.password[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="eo">EO</label>
                <Dropdown id="eo_id" dataKey="eo_id" v-model="forms.eo_id" :loading="loadingDropdownEO"
                    :options="dataDropdownEO" :class="{ 'p-invalid': errorAdd.eo_id }"
                    optionLabel="label" optionValue="eo_id" placeholder="Pilih EO"
                    :filter="true" :showClear="true" @filter="searchDropdownEO($event, 'add')"
                />
                <small class="p-invalid" style="color: red" v-if="errorAdd.eo_id" >{{ errorAdd.eo_id[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="no_hp">No Hp</label>
                <InputText id="no_hp" v-model="forms.no_hp" required="true"  :class="{ 'p-invalid': errorAdd.no_hp }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.no_hp" >{{ errorAdd.no_hp[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="email">Email</label>
                <InputText id="email" type="email" v-model="forms.email" required="true"  :class="{ 'p-invalid': errorAdd.email }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.email" >{{ errorAdd.email[0] }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownEO: false,

            // dataDropdown
            dataDropdownEO: null,

            // addNew
            forms: {
                username: null,
                nama: null,
                eo_id: null,
                no_hp: null,
                email: null,
                password: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownEO('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // DROPDOWN
        searchDropdownEO(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.deo.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownEO = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/eo',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownEO = res.data.data;
                            this.loadingDropdownEO = false;
                        } else if (purpose == null) {
                            this.dataDropdownEO = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/sp/create',
                data: {
                    "username": this.forms.username,
                    "nama": this.forms.nama,
                    "eo_id": this.forms.eo_id,
                    "no_hp": this.forms.no_hp,
                    "email": this.forms.email,
                    "password": this.forms.password,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.username = null;
            this.forms.nama = null;
            this.forms.eo_id = null;
            this.forms.no_hp = null;
            this.forms.email = null;
            this.forms.password = null;
        },
    }
}
</script>