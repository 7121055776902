<template>
    <Message ref="message" severity="error" :closable="true" v-if="errors.message"> 
        <div v-if="Array.isArray(errors.message)">
            <ul v-for="(err, index) in errors.message" :key="index">
                <li>{{err[0]}}</li>
            </ul>
        </div>
        <div v-else>
            {{errors.message}}
        </div>
    </Message>
    <Message ref="message" severity="error" :closable="true" v-if="errors.error">
        <div v-if="Array.isArray(errors.error)">
            <ul v-for="(err, index) in errors.error" :key="index">
                <li>{{err[0]}}</li>
            </ul>
        </div>
    </Message>
    <Message ref="message" severity="error" :closable="true" v-if="typeof(errors) === 'string'">
        {{errors}}
    </Message>
    <Message ref="message" severity="error" :closable="true" v-if="typeof(errors) === 'object' && errors.length > 0">
            <ul v-for="(err, index) in errors" :key="index">
                <li>{{err}}</li>
            </ul>
    </Message>
    <Message ref="message" severity="error" :closable="true" v-if="typeof(errors) === 'undefined'">
        Silahkan coba lagi dan refresh kembali
    </Message>
</template>

<script>

export default {
   props: ['errors'],
}

</script>
